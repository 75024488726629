import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import eventBus from "./utils/eventBus";

gsap.registerPlugin(ScrollTrigger);

export default function Animations() {
  // gsap.from(".intro-title", {
  //   duration: 1,
  //   opacity: 0,
  //   scrollTrigger: {
  //     trigger: ".intro-title",
  //     start: "top 90%",
  //     end: "top 70%",
  //   },
  // });
  // gsap.from(".intro-des", {
  //   duration: 1,
  //   opacity: 0,
  //   scrollTrigger: {
  //     trigger: ".intro-des",
  //     start: "top 90%",
  //     end: "top 70%",
  //   },
  // });
  // gsap.from(".intro-btn", {
  //   opacity: 0,
  //   scrollTrigger: {
  //     trigger: ".intro-btn",
  //     start: "top 90%",
  //     end: "top 70%",
  //   },
  // });
  // gsap.from(".intro-img", {
  //   duration: 1,
  //   opacity: 0,
  //   scrollTrigger: {
  //     trigger: ".intro-img",
  //     start: "top 65%",
  //     end: "top 50%",
  //   },
  // });
  // gsap.from(".intro-icon", {
  //   duration: 1,
  //   opacity: 0,
  //   scrollTrigger: {
  //     trigger: ".intro-icon",
  //     start: "top 65%",
  //     end: "top 50%",
  //   },
  // });
}

// two col loop

// gsap.from(".two-col-loop1", {
//   stagger: 2,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".two-col-loop1",
//     start: "top 75%",
//     end: "top 60%",
//   },
// });

// centered block

gsap.from(".centered-title", {
  duration: 1,
  opacity: 0,
  scrollTrigger: {
    trigger: ".centered-title",
    start: "top 70%",
    end: "top 60%",
  },
});

gsap.from(".centered-des", {
  duration: 1,
  opacity: 0,
  scrollTrigger: {
    trigger: ".centered-des",
    start: "top 70%",
    end: "top 60%",
  },
});

gsap.from(".centered-btn", {
  duration: 0.5,
  opacity: 0,
  scrollTrigger: {
    trigger: ".centered-btn",
    start: "top 85%",
    end: "top 70%",
  },
});

// Text image block
