import "core-js/stable";
import "regenerator-runtime/runtime";
import Form from "./js/Form";
import "./app.scss";
import "lazysizes";
import Popup from "./js/Popup";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap/dist/gsap";
import Headroom from "headroom.js";
import Join from "./js/Join";
import Mailchimp from "./js/mailchimp";
import Menu from "./js/Menu";
import Animations from "./js/Animations";

import MobMenu from "./js/MobMenu";
import Slider from "./js/Slider";
import GMap from "./js/GMap";
import "htmx.org";

let actions = {};
let pageExit = {};

function registerAction(action) {
  actions[action.name] = {
    mount: action,
  };
}

function action(attr, func) {
  if (attr.indexOf(".") !== -1 || attr.indexOf("#") !== -1) {
    document.querySelectorAll(attr).forEach((el) => func(el));
  } else {
    document
      .querySelectorAll(`[data-action="${attr}"]`)
      .forEach((el) => func(el));
  }
}

gsap.registerPlugin(ScrollTrigger);
registerAction(Animations);

runActions();

function addClass(el, className) {
  var el = document.querySelectorAll(el);

  for (i = 0; i < el.length; i++) {
    if (el.classList) {
      el[i].classList.add(className);
    } else {
      el[i].className += " " + className;
    }
  }
}

function runActions() {
  document.onreadystatechange = function () {
    var state = document.readyState;
    if (state == "interactive") {
      document.getElementById("contents").style.visibility = "hidden";
    } else if (state == "complete") {
      setTimeout(function () {
        document.getElementById("interactive");
        document.getElementById("load").style.visibility = "hidden";
      }, 400);
    }
  };

  console.log("runActions");
  action("Slider", Slider);
  action("GMap", GMap);
  action("Form", Form);
  Popup(document.querySelectorAll(".popup-btn"));
  Animations();

  Mailchimp(document.querySelectorAll(".mailchimp-btn"));
  Join(document.querySelectorAll(".join-btn"));
  Menu(document.querySelectorAll(".menu-btn"));
  MobMenu(document.querySelectorAll(".mob-menu-btn"));

  const headroomElement = document.querySelector("#main-header");
  const headroom = new Headroom(headroomElement, {
    offset: 20,
  });
  headroom.init();

  const imgs = [...document.querySelectorAll(".img-reveal")];
  const triggers = [...document.querySelectorAll(".img-reveal-hover")];

  triggers.forEach((trig) =>
    trig.addEventListener("mouseenter", (e) => {
      console.log("enter");
      const activeIndex = triggers.indexOf(e.target);
      console.log(activeIndex);

      imgs.forEach((img) => {
        gsap.to(img, 0.3, {
          opacity: 0,
          onComplete() {
            imgs.forEach((img) => img.classList.add("hidden"));
            imgs[activeIndex].classList.remove("hidden");

            gsap.to(imgs[activeIndex], 0.3, {
              opacity: 1,
            });
          },
        });
      });
    })
  );
}

// Only used in dev
if (module.hot) {
  module.hot.accept();
}

(() => {
  let mounted = false;
  let service = {
    _open: false,
    overlay: document.querySelector(".overlay"),
    open() {
      this._open = true;

      gsap.to(this.overlay, 0.3, {
        opacity: 1,
      });
      document.body.classList.add("overflow-hidden");
    },
    close() {
      this._open = false;
      document.body.classList.remove("overflow-hidden");
      gsap.to(this.overlay, 0.3, {
        opacity: 0,
        onComplete: () => {
          this.overlay.innerHTML = "";
        },
      });
    },
  };

  document.body.addEventListener("htmx:load", function (evt) {
    if (!mounted) {
      mounted = true;
      return;
    }
    console.log(evt);
    service.open();
  });

  window.addEventListener("click", function (evt) {
    if (evt.target.classList.contains("close-modal")) {
      service.close();
    }
  });
})();
