import { Loader } from "google-maps";
import { style } from "./mapstyle";

export default function GMap(el) {
  const lat = +el.dataset.lat;
  const lon = +el.dataset.lon;

  const options = {
    /* todo */
  };

  const loader = new Loader("AIzaSyC-w3W41mXN-_mHwI_uXqHPhlXqzhcvkG4", options);

  function initMap() {
    console.log("init map");
    loader.load().then(function (google) {
      el.style.height = "620px";
      const map = new google.maps.Map(el, {
        center: { lat: lat, lng: lon },
        zoom: 16,
        mapTypeControl: false,
        styles: style,
      });

      new google.maps.Marker({
        position: { lat: lat, lng: lon },
        map,
      });
    });
  }

  initMap();

  return () => {};
}
